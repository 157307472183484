import React from 'react'
import Seo from '../containers/SEO'
import Layout from '../containers/Layout'
import EmailSearchResultsDashboard from '../common/search/EmailSearchResultsDashboard'
import { SearchProvider } from '@elastic/react-search-ui' // https://github.com/elastic/search-ui
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'
import Search from '../common/search/elastic/Search'

const connector = new AppSearchAPIConnector({
  searchKey: process.env.GATSBY_ELASTIC_SEARCH_KEY,
  engineName: process.env.GATSBY_ELASTIC_ENGINE_NAME,
  endpointBase: process.env.GATSBY_ELASTIC_URL,
  cacheResponses: false
})

const facetOptions = {
  sender_party_affiliation: {
    type: 'value'
  },
  sender_name: {
    type: 'value',
    size: 100
  },
  isdishonestsubject: {
    type: 'value'
  }
}

const searchQueryOptions = {
  result_fields: {
    text_plain: {
      snippet: {
        size: 600,
        fallback: true
      }
    },
    stripped_html: {
      snippet: {
        size: 600,
        fallback: true
      }
    },
    subject: {
      snippet: {
        size: 100,
        fallback: true
      }
    },
    from: { raw: {} },
    date: { raw: {} },
    id: { raw: {} },
    sender_name: { raw: {} },
    sender_office_sought: { raw: {} },
    sender_party_affiliation: { raw: {} }
  },
  // https://docs.elastic.co/search-ui/api/react/components/facet#example-of-an-or-based-facet-filter
  // https://docs.elastic.co/search-ui/api/core/configuration#disjunctive-faceting
  disjunctiveFacets: ['sender_party_affiliation', 'isdishonestsubject', 'sender_name'],
  facets: facetOptions
}

const configurationOptions = {
  debug: false, // debug info shows up in console
  apiConnector: connector,
  searchQuery: searchQueryOptions,
  initialState: {
    resultsPerPage: 20,
    // scroll: '10m',
    // current: 300
    totalPages: 10000
  },
  autocompleteQuery: {
    suggestions: {
      types: {
        documents: {
          fields: ['subject', 'text_plain']
        }
      },
      size: 4
    }
  }
}

const Emails = () => {
  return (
    <SearchProvider config={configurationOptions}>
      <Layout>
        <Seo title='Search Email Database' />
        <section className='pt-20 pb-0'>
          <div className='container mx-auto !py-0'>
            <h1 className='mb-8 leading-tight border-b-4 border-blue-400'>
              Search Email Database
              <br />
              of U.S. 2020 Election Emails
            </h1>
            <Search />
          </div>
        </section>
        <EmailSearchResultsDashboard />
      </Layout>
    </SearchProvider>
  )
}

export default Emails
