import React from "react";
import { Formik, Form, Field } from "formik";
import { withSearch } from "@elastic/react-search-ui";
// import FormikDebug from "../../utils/FormikDebug";
import TextInput from "../../../common/fields/TextInput";


const Search = (props) => {
  const { setSearchTerm, searchTerm } = props;

  const searchValues = searchTerm.split(' NOT ')

  const initialValues = {
    containing: {
      content: searchValues[0] || '',
    },
    excluding: {
      content: searchValues[1] || '',
    },
  };

  const handleSubmit = async ({ containing, excluding }) => {
    let searchTerm = containing.content
    if (excluding.content) {
      searchTerm += ` NOT ${excluding.content}`
    }
    setSearchTerm(searchTerm);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => handleSubmit(values)}
    >
      {({ errors, values, setFieldValue, dirty, isValid }) => (
        <Form id="advancedSearch">
          <div className="py-4">
            <div className="flex flex-col w-full md:flex-row ">
              <div className="md:w-4/12 lg:w-3/12">
                <label htmlFor="containing" className="pt-3 label">
                  Containing These Words
                </label>
              </div>
              <div className="md:w-8/12 lg:w-9/12">
                <Field
                  autoComplete="off"
                  name="containing.content"
                  type="text"
                  component={TextInput}
                  className=""
                  onChange={(value) => {
                    setSearchTerm(value)
                    setFieldValue("containing.content", value)
                  }}
                  placeholder="'Finance Reform'"
                />
              </div>
            </div>
            <div className="flex flex-col w-full pt-5 md:flex-row ">
              <div className="md:w-4/12 lg:w-3/12">
                <label htmlFor="containing" className="pt-3 label">
                  Excluding This Phrase
                </label>
              </div>
              <div className="md:w-8/12 lg:w-9/12">
                <Field
                  autoComplete="off"
                  name="excluding.content"
                  type="text"
                  component={TextInput}
                  className=""
                  onChange={(value) => {
                    setFieldValue("excluding.content", value)
                  }
                  }
                  placeholder=""
                />
              </div>
            </div>
            <div className="py-4">
              <div className="flex flex-col w-full md:flex-row ">
                <div className="md:w-4/12 lg:w-3/12"></div>
                <div className="md:w-8/12 lg:w-9/12">
                  <input
                    type="submit"
                    className="btn btn-blue"
                    value="Search Emails"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <FormikDebug /> */}
        </Form>
      )}
    </Formik>
  );
};

export default withSearch((context, props) => {
  const { setSearchTerm, searchTerm } = context;
  return { setSearchTerm, searchTerm };
})(Search);
