import { withSearch, Facet } from "@elastic/react-search-ui";
import React from "react";
import EmailSearchResults from "./EmailSearchResults";

const EmailSearchResultsDashboard = ({ results, searchTerm, totalResults }) => {
  // If there is no search term show nothing
  // if there is a search term show the complete form
  // If there a search term and no results, show a message
  return searchTerm ? (
    <div>
      <EmailSearchResults results={results} totalResults={totalResults} />
    </div>
  ) : (
    <>{/* show nothing at all  */}</>
  );
};

export default withSearch((context, props) => {
  const { results, searchTerm, totalResults } = context;
  return { results, searchTerm, totalResults };
})(EmailSearchResultsDashboard);
